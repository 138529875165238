.footer{
    box-shadow: 0 0 5px 3px #ff6a00fe;
    height: 175px;
    background-color: #13110c;
}

.footer.purple{
    box-shadow: 0 0 5px 3px #ff006afe;
    height: 175px;
    background-color: #13110c;
}

.footer-icon:hover {
    fill: #ff6a00fe;
}

.footer-icon.purple:hover {
    fill: #ff006afe;
}