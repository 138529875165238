.orange-btn {
  background-color: #ff6a00fe !important;
  text-transform: uppercase;
  border: none !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.1rem;
  font-family: "Koulen" !important;
  text-shadow: 1px 1px #000000fe;
}

.purple-btn {
  background-color: #ff006afe !important;
  text-transform: uppercase;
  border: none !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.1rem;
  font-family: "Koulen" !important;
  text-shadow: 1px 1px #000000fe;
}

.orange-btn:hover {
  transform: scale(1.1);
}

.purple-btn:hover {
  transform: scale(1.1);
}
