.navbar-nav a:hover {
  transform: scale(1.2);
}

.navbar-nav a.active {
  color: #ff6a00fe !important;
  text-shadow: 0 0 10px #ff6a00fe;
}

.navbar-nav.purple a.active {
  color: #ff006afe !important;
  text-shadow: 0 0 10px #ff006afe;
}

.navigation {
  box-shadow: 0 0 5px 3px #ff6a00fe;
}

.navigation.purple {
  box-shadow: 0 0 5px 3px #ff006afe;
}

.headshot:hover {
  box-shadow: 0 0 10px 15px #ff6a00fe, 0 0 50px 20px #ff6a00fe;
}

.headshot.purple:hover {
  box-shadow: 0 0 10px 15px #ff006afe, 0 0 50px 20px #ff006afe;
}

.headshot {
  box-shadow: 0 0 10px 10px #ff6a00fe;
}

.headshot.purple {
  box-shadow: 0 0 10px 10px #ff006afe;
}

.orange-theme{
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ff6a00fe;
}

.orange-theme.orange {
  animation: match 1000ms ease-in-out infinite;
  box-shadow: 0 0 8px 3px #ff6a00fe;
}

.purple-theme{
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ff006afe;
}

.purple-theme.purple {
  animation: match 1000ms ease-in-out infinite;
  box-shadow: 0 0 8px 3px #ff006afe;
}

@keyframes pulse{
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

.circle_1{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_1 32s linear infinite;
}

.circle_1.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_1{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_2{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%;; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_2 32s linear infinite;
  animation-delay: -2s;
}

.circle_2.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_2{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_3{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_3 32s linear infinite;
  animation-delay: -4s;
}

.circle_3.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_3{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_4{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_4 32s linear infinite;
  animation-delay: -6s;
}

.circle_4.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_4{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
    translate(-110px)
    rotate(360deg);
}
}

.circle_5{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_5 32s linear infinite;
  animation-delay: -8s;
}

.circle_5.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_5{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
    translate(-110px)
    rotate(360deg);
}
}

.circle_6{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_6 32s linear infinite;
  animation-delay: -10s;
}

.circle_6.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_6{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_7{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_7 32s linear infinite;
  animation-delay: -12s;
}

.circle_7.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_7{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_8{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_8 32s linear infinite;
  animation-delay: -14s;
}

.circle_8.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_8{
  0%{
    transform:rotate(0deg)
              translate(-107px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(-360deg)
              translate(-107px)
              rotate(360deg);
  }
}

.circle_9{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_9 32s linear infinite;
  animation-delay: -15s;
}

.circle_9.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_9{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_10{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_10 32s linear infinite;
  animation-delay: -1s;
}

.circle_10.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_10{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_11{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_11 32s linear infinite;
  animation-delay: -3s;
}

.circle_11.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_11{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_12{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_11 32s linear infinite;
  animation-delay: -5s;
}

.circle_12.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_12{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_13{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_13 32s linear infinite;
  animation-delay: -7s;
}

.circle_13.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_13{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_14{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_14 32s linear infinite;
  animation-delay: -9s;
}

.circle_14.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_14{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}

.circle_15{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_15 32s linear infinite;
  animation-delay: -11s;
}

.circle_15.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_15{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}


.circle_16{
  box-shadow: 0 0 10px 6px #ff6a00fe;
  width:6px;
  height:6px; border-radius: 50%;
  background:rgb(255, 255, 255);
 
  position:absolute;
  top:0; bottom: 49%; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle_16 32s linear infinite;
  animation-delay: -13s;
}

.circle_16.purple{
  box-shadow: 0 0 10px 6px #ff006afe;
}

@keyframes circle_16{
  0%{
    transform:rotate(0deg)
              translate(-137px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-137px)
              rotate(-360deg);
  }
}