.card {
  background-color: #422e50 !important;
  box-shadow: 0 0 10px 5px #ff6a00fe;
}

.card.purple {
  box-shadow: 0 0 10px 5px #ff006afe;
}

.card-header {
  background-color: #1e242e !important;
  border-bottom: 1px solid #1e242e !important;
  border-top: 1px solid #1e242e !important;
}

.card-footer {
  background-color: #1e242e !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

@media only screen and (min-width: 992px) {
  .card:hover {
    transform: scale(1.05);
    z-index: 1;
  }
}