.App {
  text-align: center !important;
  letter-spacing: 0.15rem !important;
  text-transform: uppercase !important;
  font-family: "Koulen" !important;

  min-height: 100vh;
  font-size: calc(10px + 1.5vmin);
  color: white !important;
  background: linear-gradient(
    67deg,
    #13110c 28%,
    #2b193bfe  42%,
    #130f0c 63%
  ) !important;
}


/* page structure */
.orange-bar-1 {
  margin: 0 auto;
  display: block;
  width: 5px;
  height: 45px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 10px 5px #ff6a00fe; 
}

.purple-bar-1 {
  margin: 0 auto;
  display: block;
  width: 5px;
  height: 45px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 10px 5px #ff006afe; 
}

.orange-border {
  margin: 0 auto;
  display: table;
  text-shadow: 1px 1px black;
  color: #ff6a00fe;
  border-radius: 3px;
  padding: 5px 20px;
  background-color: white;
  box-shadow: 0 0 10px 5px #ff6a00fe;
}

.purple-border {
  margin: 0 auto;
  display: table;
  text-shadow: 1px 1px black;
  color: #ff006afe;
  border-radius: 3px;
  padding: 5px 20px;
  background-color: white;
  box-shadow: 0 0 10px 5px #ff006afe;
}

/* .orange-border:hover {
transform: scale(1.10);
cursor: context-menu;
} */

@media only screen and (max-width: 992px) {
  .xsm-none {
    display: none !important;
  }
  .xsm-block {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) {
  .xsm-block {
    display: none !important;
  }
}
