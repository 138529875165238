.front {
  transform: rotateY(90deg);
  transition: all ease-in 0.05s;
  position: absolute;
}

.flipped .front {
  transform: rotateY(0deg);
}

.flipped .unmatched .front {
  background-color: #ee1c1cfe;
  box-shadow: 0 0 6px 6px #ee1c1cfe;
  animation: unmatch 300ms ease-in-out forwards;
}

@keyframes unmatch {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.flipped .matched .front {
  background-color: #42b30a;
  box-shadow: 0 0 6px 8px #42b30a;
  animation: match 1000ms ease-in-out forwards;
}

@keyframes match {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

.back-orange {
  box-shadow: 0 0 6px 6px #ff6a00fe;
  transition: all ease-in 0.05s;
  background-color: #ff6a00fe;
}

.back-orange:hover {
  cursor:pointer;
}

.back-purple {
  box-shadow: 0 0 6px 6px #ff006afe;
  transition: all ease-in 0.05s;
  background-color: #ff006afe;
}

.back-purple:hover {
  cursor:pointer;
}

.flipped .back {
  transform: rotateY(90deg);
}

.back:hover {
  cursor: pointer;
}

.matcher-spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }