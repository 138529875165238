.modals-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.877);
}

.modals {
  position: relative;
  top: 95px;
}

.modal-content {
  box-shadow: 0 0 10px 5px #ff6a00fe;
  background: linear-gradient(
    67deg,
    #13110c 5%,
    #2b193bfe 52%,
    #130f0c 100%
  ) !important;
}

.modal-content.purple {
  box-shadow: 0 0 10px 5px #ff006afe;
  background: linear-gradient(
    67deg,
    #13110c 5%,
    #2b193bfe 52%,
    #130f0c 100%
  ) !important;
}

.btn-close {
  background-color: rgb(237, 230, 230) !important;
  opacity: 1 !important;
}

.zoom-modal {
  animation: zoom 1000ms ease-in-out forwards;
}

@keyframes zoom {
  from {
    transform: scale(0.25);
  }
  to {
    transform: scale(1);
  }
}
