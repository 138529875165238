.gemunu {
  text-shadow: none;
  font-family: "Gemunu Libre" !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.serif {
  font-family: "Source Serif Pro", cursive !important;
  text-transform: lowercase;
}

.koulen {
  letter-spacing: 0.15rem !important;
  text-transform: uppercase !important;
  font-family: "Koulen" !important;
  color: white !important;
}

.orange-ts-2 {
  text-shadow: 2px 1px #ff6a00fe;
}

.purple-ts-2 {
  text-shadow: 2px 1px #ff006afe;
}

.orange-ts-1 {
  text-shadow: 0.5px 0.5px #ff6a00fe;
}

.purple-ts-1 {
  text-shadow: 2px 1px #ff006afe;
}

.black-ts {
  text-shadow: 2px 1px #000000fe;
}

.white-ts {
  text-shadow: 0.5px 0.5px #f8f5f5fe;
}

.orange-font-color {
  color: #ff6a00fe;
  line-height: 1.5;
}

.purple-font-color {
  color: #ff006afe;
  line-height: 1.5;
}
