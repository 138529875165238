img.orange-skills-logo {
  box-shadow: 0 0 4px 5px #ff6a00fe;
}

img.orange-skills-logo:hover {
  cursor: context-menu;
  margin-top: -8px;
  margin-bottom: 8px;
}

img.purple-skills-logo {
  box-shadow: 0 0 4px 5px #ff006afe;
}

img.purple-skills-logo:hover {
  cursor: context-menu;
  margin-top: -8px;
  margin-bottom: 8px;
}
